$primary-color: #626c83;
$accent-color: #55636d;
$secondary-color: #cc9085;
$alert-color: #d64f4f;
$white-color: #fff;
$devider-color: #ccd2e0;
$box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.42);
// $admin-background: #dae0e5;
$admin-background: #f8f9fc;
$border: #ccc;
$background-light: #f9f9f9;
$overlay: rgba(15, 15, 16, 0.5);
$snackbar-bg: #323232;
$text-color: #55636d;
$icons-color: #77797b;
$shapes-color: rgb(255 255 255 / 50%);
$lines-color: #c0c0c0;
$scrollbar-color: rgb(204 144 133 /0.1);
$secondary-color-opacity-5: rgb(204 144 133 / 0.3);
$scroll-color: #e5e7e8;
$chat-color: rgb(204 144 133 / 0.2);
$border-color: #626c83;
$font-family-en: "Roboto", sans-serif;
// $font-family-ar: "Tajawal", sans-serif;
$font-family-ar: "IBM Plex Sans Arabic", sans-serif;

:root {
  --secondary-color: #cc9085;
  --primary-color: #626c83;
  --alert-color: #d64f4f;
  --devider-color: #ccd2e0;
  --accent-color: #55636d;
}
