// @import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700;900&display=swap");
// @import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import "variables";
@import "function";
@import "theme";
@import "mat-theme";
@import "datatable";
// @import "primeng/resources/themes/lara-light-blue/theme.css";
// @import "primeng/resources/primeng.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  // text-decoration: none !important;
  scrollbar-width: thin !important;
  scrollbar-color: $secondary-color-opacity-5 $scroll-color !important;
  @media only screen and (max-width: 768px) {
    scrollbar-color: $secondary-color-opacity-5 $scroll-color !important;
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: $scroll-color;
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb {
  background: $secondary-color-opacity-5;
  border-radius: 2rem;
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease;
  -moz-transition: width 0.3s ease;
  -ms-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover {
  background: $secondary-color-opacity-5;
}

@media only screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0.3rem;
    height: 0.3rem;
  }
  ::-webkit-scrollbar-track {
    background: secondary-color-opacity-5;
  }
  ::-webkit-scrollbar-thumb {
    background: secondary-color-opacity-5;
  }
}
html,
body {
  height: 100%;
  overflow: hidden;
}

html {
  direction: rtl !important;
  .mat-dialog-container,
  .mdc-dialog__container {
    direction: rtl !important;
  }
}

img {
  width: 100%;
  display: block;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a,
h1,
h2,
h3,
span,
table,
td,
tr,
button,
input,
li,
ul,
label,
div,
pre {
  font-family: $font-family-ar;
}

ul {
  list-style: none;
}
// shared

.display-large {
  @include textCustom(64, 94, 700);
}

.display-large-2 {
  @include textCustom(40, 68, 700);
}

.display-semiBold {
  @include textCustom(48, 72, 600);
}

.display-semiBold-2 {
  @include textCustom(40, 68, 600);
}

.display-medium {
  @include textCustom(48, 72, 700);
}

.display-medium-2 {
  @include textCustom(40, 68, 500);
}

.display-small {
  @include textCustom(48, 72, 500);
}

.display-small {
  @include textCustom(40, 64, 400);
}

.headline-large {
  @include textCustom(36, 60, 700);
}

.headline-large-2 {
  @include textCustom(32, 56, 700);
}

.headline-semiBold {
  @include textCustom(36, 60, 600);
}

.headline-semiBold-2 {
  @include textCustom(32, 56, 600);
}

.headline-medium {
  @include textCustom(36, 60, 500);
}

.headline-medium-2 {
  @include textCustom(32, 56, 500);
}

.headline-small {
  @include textCustom(36, 60, 400);
}

.headline-small-2 {
  @include textCustom(30, 56, 400);
}

.title-large {
  @include textCustom(28, 56, 700);
}

.title-large-2 {
  @include textCustom(24, 48, 700);
}

.title-large-3 {
  @include textCustom(22, 40, 700);
}

.title-semiBold {
  @include textCustom(28, 56, 600);
}

.title-semiBold-2 {
  @include textCustom(24, 48, 600);
}

.title-semiBold-3 {
  @include textCustom(22, 40, 600);
}

.title-medium {
  @include textCustom(28, 56, 500);
}

.title-medium-2 {
  @include textCustom(24, 48, 500);
}

.title-medium-3 {
  @include textCustom(22, 40, 500);
}

.title-small {
  @include textCustom(28, 56, 400);
}

.title-small-2 {
  @include textCustom(24, 48, 400);
}

.title-small-3 {
  @include textCustom(22, 40, 400);
}

.label-large {
  @include textCustom(20, 38, 700);
}

.label-large-2 {
  @include textCustom(18, 36, 700);
}

.label-semiBold {
  @include textCustom(20, 38, 600);
}

.label-semiBold-2 {
  @include textCustom(18, 36, 600);
}

.label-medium {
  @include textCustom(20, 38, 500);
}

.label-medium-2 {
  @include textCustom(18, 36, 500);
}

.label-small {
  @include textCustom(20, 38, 400);
}

.label-small-2 {
  @include textCustom(18, 36, 400);
}

.body-large {
  @include textCustom(16, 32, 700);
}

.body-large-2 {
  @include textCustom(14, 26, 700);
}

.body-large-3 {
  @include textCustom(12, 24, 700);
}

.body-semiBold {
  @include textCustom(16, 32, 600);
}

.body-semiBold-2 {
  @include textCustom(14, 26, 600);
}

.body-semiBold-3 {
  @include textCustom(12, 24, 600);
}

.body-medium {
  @include textCustom(16, 32, 500);
}

.body-medium-2 {
  @include textCustom(14, 26, 500);
}

.body-medium-3 {
  @include textCustom(12, 24, 500);
}

.body-small {
  @include textCustom(16, 32, 400);
}

.body-small-2 {
  @include textCustom(14, 26, 400);
}

.body-small-3 {
  @include textCustom(12, 24, 400);
}

.body-small-4 {
  @include textCustom(10, 24, 400);
}

.d-flex {
  display: flex !important;
  align-items: center;
}
.d-flex-center {
  @extend .d-flex;
  justify-content: center;
}

.d-flex-between {
  @extend .d-flex;
  justify-content: space-between;
}
.field {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  margin-top: pxToRem(32);
  text-align: start;
  input {
    @extend .label-medium-2;
    color: $text-color;
  }

  &__label {
    @extend .body-small;
    color: $text-color;

    &__required {
      @extend .body-small;
      color: $alert-color;
      font-size: 2rem;
    }
  }
}

.btn {
  height: pxToRem(52) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: pxToRem(8);
  width: 100%;
  border: none;
  cursor: pointer;
}

.btn-secondary {
  background: $secondary-color;
  box-shadow: $box-shadow;
  color: $white-color !important;
  @extend .label-large;
}

.btn-primary {
  background-color: $secondary-color !important;
  border-radius: pxToRem(8) !important;
  padding: pxToRem(8) !important;
  @extend .d-flex-between;
  color: $white-color !important;
  @extend .label-medium;
  width: auto;
  gap: pxToRem(8);
  direction: ltr;

  mat-icon {
    font-size: pxToRem(20) !important;
    height: pxToRem(20) !important;
    width: pxToRem(20) !important;
    margin: 0 !important;
    @extend .d-flex-center;
  }
}

.btn-primary-outline {
  @extend .label-medium;
  color: $secondary-color !important;
  border: 1px solid $secondary-color !important;
  background-color: transparent !important;
  gap: pxToRem(8);
  white-space: nowrap;
  justify-content: center !important;
  &::after {
    font-family: "Material Symbols Outlined";
    content: "download";
    position: relative;
    display: block;
    font-size: pxToRem(22);
    font-weight: 400;
    color: $primary-color;
  }
}

.btn-cancel {
  @extend .label-medium;
  color: $text-color !important;
  background-color: transparent !important;
}

.gap-1 {
  gap: pxToRem(24);
}

.disabled {
  pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.inquiry_status {
  @extend .body-medium-2;
  @extend .d-flex-center;
  border-radius: pxToRem(8) !important;
  padding: pxToRem(3) pxToRem(7);
  white-space: nowrap;
  width: 6rem;
  margin: auto;
  border: 0.5px solid;
  // width: 60%;
  // padding: pxToRem(1.8) pxToRem(12);
  &[data-value="active"] {
    border-color: $secondary-color;
    color: $secondary-color;
    background: rgba(7, 178, 126, 0.1);
  }
  &[data-value="initiated"] {
    border-color: #4053ff;
    color: #4053ff;
    background: #eaf0ff;
  }
  &[data-value="closed"] {
    border-color: $chat-color;
    color: $chat-color;
    background: #dfefef;
  }
  &[data-value="escalated_to_admin"] {
    border-color: #df9312;
    color: #df9312;
    background: rgba(245, 158, 11, 0.2);
    padding: pxToRem(3) pxToRem(10) !important;
  }
  &[data-value="reported"] {
    border-color: $alert-color;
    color: $alert-color;
    background: rgba(186, 57, 57, 0.2);
  }
  &[data-value="rejected"] {
    border-color: #e54141;
    color: #e54141;
    background: rgba(186, 57, 57, 0.2);
  }
  &[data-value="delayed"] {
    border-color: $snackbar-bg;
    color: $snackbar-bg;
    background: #ccc;
  }
}
.p-dropdown.p-component {
  min-width: pxToRem(210) !important;
}
.calendarSelect input {
  text-align: center;
}
.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus {
  border-color: #07b27e !important;
}
.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus {
  box-shadow: none !important;
}
.chart {
  div {
    display: flex;
    justify-content: center;
    canvas {
      min-width: 300px;
      max-width: 320px;
      min-height: 300px;
      max-height: 320px;
      width: 320px !important;
      height: 320px !important;
    }
    @media only screen and (max-width: 1250px) {
      canvas {
        width: 55% !important;
      }
    }
    @media only screen and (max-width: 480px) {
      canvas {
        width: 100% !important;
      }
    }
  }
}
